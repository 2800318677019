




























































































































































































































.seachStyle {
  .searchList {
    .listLabel {
      min-width: 88px !important;
    }
  }
}
